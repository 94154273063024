<template lang="pug">
include ../../../configs/template
v-form.row.mx-3
  div.col-12
    +textarea-validation('body.comment', 'comment', '["required", "alphaENText"]')
  div.col-12.text-center
    v-btn(color="error" @click="setRejectStatement") {{$t('setReject')}}
</template>

<script>
import { RejectStatementSRBFromISC } from '@/mixins/validationRules'
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      body: {
        comment: '',
        additional_info: {}
      },
      status_document: 23 // rejected
    }
  },
  validations () { return RejectStatementSRBFromISC() },
  methods: {
    ...mapActions(['rejectStatementSRBtoISC']),

    async setRejectStatement () {
      if (this.$v.$invalid) return this.$v.$touch()

      const data = { body: this.body, status_document: this.status_document }
      if (this.$route.params.documentID) data.documentID = this.$route.params.documentID

      const confirmation = await this.$swal({
        title: this.$t('warning'),
        text: this.$t('rejectingConfirmation'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('confirm')],
        dangerMode: true
      })
      if (!confirmation) return false

      const response = await this.rejectStatementSRBtoISC(data)
      if ([200, 201].includes(response?.code)) {
        this.$emit('getData')
        this.$v.$reset()
      }
    }
  }
}
</script>
